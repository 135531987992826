* {
  scroll-behavior: smooth;
}

:root {
  /*
  #00B2B0 grün
  #D8DC40 hell grün
  #FF6920 orange
  */
  --dark-green: #00AAAD;
  --light-green: #d8dc40;
  --orange: #ff6920;
}

@font-face {
  font-family: TradeGothic;
  src: url('./assets/font/TradeGothicLTStd.otf') format('opentype');
}

@font-face {
  font-family: TradeGothicHeader;
  src: url('./assets/font/TradeGothicLTStd-Bd2.otf') format('opentype');
}

body {
  margin: 0;
  font-family: TradeGothic, 'sans-serif', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide clear search button and we add manually. Firefox doesnt support it */
input[type='search']::-webkit-search-cancel-button {
  display: none;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

@media screen and (max-width: 425px) {
  #root {
    height: calc(100vh - 58px);
    margin-top: 58px;
  }
}

@keyframes blink {
  0% {
    color: red;
  }
  100% {
    color: black;
  }
}

video {
  max-width: 100%;
}

.leaflet-map {
  font-size: 1em;
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -5;
}

.leaflet-container a.direction-link {
  color: var(--dark-green) !important;
  text-decoration: initial !important;
}

.leaflet-container a.fuhrer-link {
  color: white !important;
  text-decoration: none !important;
}

.leaflet-container a.direction-link-osm {
  color: white !important;
  text-decoration: initial !important;
}
.leaflet-map-pane .category-icon svg {
  width: 12px;
  height: 12px;
}

.centered {
  width: 50vw;
  margin: 0 auto;
}
.lefty {
  width: 33vw;
  margin-left: 5vw;
}
.righty {
  width: 33vw;
  margin-left: 62vw;
}

.bold {
  font-weight: 700;
}

/* ++++++++++++++++++++++++++++++++

Popups 

++++++++++++++++++++++++++++++++++ */

.leaflet-popup {
  z-index: 3;
  width: 80vw;
  max-width: 500px;
}

.leaflet-popup-content {
  width: 100% !important;
  margin: 0 !important;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
  max-height: 80vh;
  min-height: 50px;
  border-radius: 12px;
}

.leaflet-popup-content p {
  margin: unset !important;
}

.leaflet-popup .popup-wrap .popup__mediabox {
  float: left;
  padding: 0 1em 0.8em 0;
}

.strong {
  text-transform: capitalize;
  font-weight: 800;
}

.leaflet-popup .popup-wrap .popup__mediabox:last-of-type:not(.top_pic) {
  padding: 0.8em 1em 0 0;
}
.leaflet-popup .popup-wrap .popup__mediabox * {
  max-width: 100%;
}
.leaflet-popup .popup-wrap .popup__mediabox.top_pic {
  max-width: 150px;
}
.leaflet-popup .popup-wrap .popup__mediabox {
  max-width: 230px;
}
.leaflet-popup .popup-wrap .flex {
  display: flex;
  padding-top: 0.8em;
  max-width: 100%;
  clear: both;
}

.leaflet-popup .popup-wrap .flex section {
  flex-basis: 50%;
}
.leaflet-popup .popup-wrap .flex section img {
  max-width: 100%;
}
.leaflet-popup .popup-wrap .flex section:first-of-type {
  padding: 0 5px 0 0;
}
.leaflet-popup .popup-wrap .flex section:last-of-type {
  padding: 0 0 0 5px;
}

.leaflet-popup .popup-wrap img:hover {
  cursor: zoom-in;
}

.form-error {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.form-error textarea {
  border: 1px solid #ccc;
  padding: 0.6em;
  width: 100%;
  border-radius: 4px;
}

.form-error textarea:focus {
  border: 1px solid var(--dark-green);
}

.form-error textarea:hover {
  border: 1px solid var(--dark-green);
}

.form-error textarea:focus-within {
  border: 1px solid var(--dark-green);
}

.form-error textarea:focus-visible {
  border: 2px solid var(--dark-green);
  stroke-width: 0;
  outline: 0;
}
.form-error textarea {
  min-height: 100px;
}

.teilnehmer_popup .close-button,
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  cursor: pointer;
  background-color: #bcc2bc !important;
  color: #fff !important;
  border: none;
  border-radius: 50%;
  font-weight: bolder;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 0 0 3px 0 !important;
  font-size: 19px;
  height: 24px !important;
  width: 24px !important;
  top: 12px !important;
  right: 12px !important;
}
/* firefox Hack */
@-moz-document url-prefix() {
  .teilnehmer_popup .close-button,
  .leaflet-popup-close-button {
    padding: 0 0 4px 0;
  }
}

#info {
  margin-top: -250px;
  padding-top: 320px;
}

.leaflet-popup-close-button:hover {
  background-color: #fff;
  color: #00AAAD;
}

@media screen and (max-width: 425px) {
  .leaflet-popup-content-wrapper {
    width: 100%;
  }
  .leaflet-popup {
    /*    transform: translate(0px, 70px) translate(0px, 0px) !important;
    bottom: 0 !important;
    top: 0 !important;
    left:0 !important;
    right: 0 !important;*/
    width: 96vw;
    height: calc(100vh - 94px);
    margin-bottom: -100px;
    bottom: -40px !important;
    position: absolute;
  }

  .leaflet-popup-content {
    background-color: #fff;
    /*max-height: 80vh;*/
    overflow: auto;
  }

  .leaflet-popup-tip {
    display: none;
  }
}

#partner_popup h1 {
  font-size: 1.2em;
  line-height: 1.4em;
}
#partner_popup p {
  padding: 0.2em;
  margin: 0;
  clear: both;
}
#partner_popup button {
  border: none;
  background: none;
  color: #ff6920;
  font-size: 1.2em;
  cursor: pointer;
  float: right;
}

.iframe-wrap {
  min-height: 208px;
  --aspect-ratio: 4/2;
}

.iframe-wrap > :first-child {
  width: 100%;
}
.iframe-wrap > img {
  height: auto;
}
@supports (--custom: property) {
  .iframe-wrap {
    position: relative;
  }
  .iframe-wrap::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  .iframe-wrap > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .iframe-wrap {
    --aspect-ratio: 5/4;
  }
}

/* logo */

#logo img {
  height: 47px;
  position: relative;
  top: 8px;
}

@media (max-width: 959px) {
  #logo img {
    top: 0;
  }
}

.card__margin {
  margin: 10px;
}

.iframe-wrap {
  min-height: 208px;
  /* --aspect-ratio: 4/2 ; */
}

.iframe-wrap > :first-child {
  width: 100%;
}
.iframe-wrap > img {
  height: auto;
}

@supports (--custom: property) {
  .iframe-wrap {
    position: relative;
  }
  .iframe-wrap::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  .iframe-wrap > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .iframe-wrap {
    --aspect-ratio: 5/4;
  }
}

/* +++++++++++++++++++++++++++++
Drawer 
++++++++++++++++++++++++++++++*/

.MuiTypography-body1 {
  font-family: 'TradeGothic', sans-serif !important;
}

.text--links.MuiList-root.MuiListItem-button,
.MuiListItemIcon-root,
.MuiListItem-root {
  width: auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiTreeItem-root.selected {
  color: var(--dark-green);
}

.icon > .MuiTreeItem-content .MuiTreeItem-label {
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.3em 0;
}

.icon > .MuiTreeItem-content .MuiTreeItem-label::before {
  content: '';
  width: 2.7em;
  height: 2.3em;
  padding-right: 1.6em;
  background-repeat: no-repeat;
  position: relative;
}

/* create filter:  ;https://codepen.io/sosuke/pen/Pjoqqp */

.house-red > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/house-user-solid.svg');
  /*filter: invert(22%) sepia(92%) saturate(5785%) hue-rotate(356deg) brightness(101%) contrast(129%);*/
  filter: invert(80%) sepia(13%) saturate(6564%) hue-rotate(4deg)
    brightness(110%) contrast(100%);
}

.people-orange > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/users-solid.svg');
  filter: invert(51%) sepia(31%) saturate(1423%) hue-rotate(336deg)
    brightness(91%) contrast(92%);
}

.hand-purple > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/hand-holding-heart-solid.svg');
  filter: invert(64%) sepia(46%) saturate(4119%) hue-rotate(293deg)
    brightness(105%) contrast(101%);
}

.medical-briefcase > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/briefcase-medical-solid.svg');
  filter: invert(23%) sepia(54%) saturate(6602%) hue-rotate(350deg)
    brightness(109%) contrast(116%);
}

.green-house > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/couch-solid.svg');
  filter: invert(70%) sepia(9%) saturate(2315%) hue-rotate(57deg)
    brightness(102%) contrast(104%);
}

.psychiatrien > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/clinic-medical-solid.svg');
  filter: invert(83%) sepia(73%) saturate(1036%) hue-rotate(151deg)
    brightness(95%) contrast(89%);
}

.blue-stones > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/house-user-solid.svg');
  filter: invert(20%) sepia(56%) saturate(3074%) hue-rotate(203deg)
    brightness(97%) contrast(106%);
}

.hand-shake > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/handshake-solid.svg');
  filter: invert(53%) sepia(9%) saturate(1181%) hue-rotate(330deg)
    brightness(86%) contrast(89%);
}

.dialog > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/user-friends-solid.svg');
  filter: invert(18%) sepia(60%) saturate(7367%) hue-rotate(260deg)
    brightness(87%) contrast(115%);
}

.house-blue > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/clinic-medical-solid.svg');
  filter: invert(57%) sepia(21%) saturate(6291%) hue-rotate(293deg)
    brightness(98%) contrast(92%);
}

.house-black > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/house-user-solid.svg');
  filter: invert(7%) sepia(21%) saturate(6291%) hue-rotate(293deg)
    brightness(98%) contrast(92%);
}

.biking-green > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/biking-solid.svg');
  filter: invert(70%) sepia(21%) saturate(6291%) hue-rotate(293deg)
    brightness(98%) contrast(92%);
}

.education-yellow > .MuiTreeItem-content .MuiTreeItem-label::before {
  background-image: url('./assets/icons/education-solid.svg');
  filter: invert(80%) sepia(80%) saturate(6291%) hue-rotate(0deg)
    brightness(98%) contrast(92%);
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: none !important;
}

@media (max-height: 812px) {
  /*.MuiTypography-body1 {font-size: 1em !important; }*/
  .MuiPaper-outlined {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiPaper-root.filter-card {
  padding: 0;
}

.MuiPaper-root.filter-card h2 {
  color: var(--dark-green);
  font-size: 1.2rem;
  margin-bottom: 0.2em;
}

.MuiPaper-root.filter-card *:not(svg) {
  font-size: 0.9rem;
}

.MuiBox-root {
  font-size: 0.96rem;
}

#search-box {
  box-shadow: 1px 1px 6px 0 #0000002a;
  margin: 0.3rem;
  height: 1.8rem;
}

#search-box form {
  display: block;
  left: 0;
}

#search-box form,
#search-box .leaflet-bar {
  box-shadow: none;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 4px;
}

#search-box input {
  position: relative;
  left: 16px;
  top: 4px;
  width: 91%;
}
.leaflet-touch .leaflet-bar a {
  width: 32px;
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  border: 2px solid #828482 !important;
}

.leaflet-control-geosearch a.leaflet-bar-part:before {
  border-top: 2px solid #828482 !important;
}

.leaflet-control-geosearch a.leaflet-bar-part::placeholder,
.leaflet-control-geosearch a.reset {
  color: #828482 !important ;
}

/*
 +++++++++ 
  cluster 
+++++++++++++
*/

.marker-cluster.lokal {
  background-color: #fad2007d;
}
.marker-cluster.lokal div {
  background-color: #fad200;
}

.search-cluster.leaflet-interactive {
  width: 54px !important;
  height: 54px !important;
}

.marker-cluster.search-cluster {
  background-color: rgba(0, 178, 175, 0.472);
}
.marker-cluster.search-cluster div {
  background-color: var(--dark-green);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.marker-cluster.bundesweit {
  background-color: #f15bb57d;
}
.marker-cluster.bundesweit div {
  background-color: #f15bb5;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 40px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
.marker-cluster span {
  line-height: 30px;
}
.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}
.leaflet-cluster-spider-leg {
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
    -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
    -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out,
    -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

/* Custom marker styles */

.leaflet-marker-icon[title='Suchstandort'] {
  z-index: 1000000 !important;
  width: 50px !important;
  height: 50px !important;
  background: #fff;
  border-radius: 50%;
  padding: 2px 4px;
}

#searchPin path {
  fill: var(--dark-green);
  z-index: 1000;
}
#searchPin .ring_outer {
  animation: opacityPulse 2s cubic-bezier(1, 0.14, 1, 1);
  animation-iteration-count: infinite;
  opacity: 0.5;
}
#searchPin .ring_inner {
  animation: opacityPulse 2s cubic-bezier(0.4, 0.74, 0.56, 0.82);
  animation-iteration-count: infinite;
  opacity: 0.8;
}
@keyframes opacityPulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 5px, 0);
  }
  75% {
    transform: translate3d(0, -3px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
.bounceInDown {
  animation-name: bounceInDown;
  animation-duration: 2s;
  animation-fill-mode: both;
}

/* im md-Popup unter der Überschrift keine Leerzeile */
.MuiDialogContent-root h3 {
  margin-bottom: 0px;
}
.MuiDialogContent-root ul {
  margin-top: 0px;
}